import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { NavLink, Route } from 'react-router-dom';

import { Container, ContentrArea, useAuth } from '@ttstr/components';
import OrderListing from '@ttstr/components/Order/OrderListing';
import Account from './Customer/Account';
import OrderDetail from './Customer/OrderDetail';
import FansOnly from './Customer/FansOnly';

const Customer: React.FC = () => {
  const { t } = useTranslation();
  const { customer, initialized } = useAuth();

  if (!initialized) return null;

  return (
    <article className="mt-5">
      <Container>
        <Helmet>
          <title>{t('CUSTOMER.LANDING_PAGE.TITLE')}</title>
        </Helmet>
        <h1 className="text-center mb-4">
          {t('CUSTOMER.LANDING_PAGE.TITLE')} {customer && customer.firstname}
          {customer && customer.eid && (
            <small className="text-muted">{t('CUSTOMER.MEMBER_NO', { no: customer.eid })}</small>
          )}
        </h1>
        <hr />
        <div className="row justify-content-center customer-nav-row">
          <NavLink className="btn btn-primary mx-2" to="/customer/orders">
            {t('ORDERS.TITLE')}
          </NavLink>
          <NavLink className="btn btn-primary mx-2" to="/customer/account">
            {t('CUSTOMER.DATA')}
          </NavLink>
          <NavLink className="btn btn-primary mx-2" to="/customer/fansonly">
            {t('CUSTOMER.FANSONLY')}
          </NavLink>
        </div>
        <hr />

        <Container>
          <ContentrArea id="customer" />
        </Container>

        <Route exact path="/customer/account" component={Account} />
        <Route exact path="/customer/orders" component={OrderListing} />
        <Route exact path="/customer/orders/:orderId" component={OrderDetail} />
        <Route exact path="/customer/fansonly" component={FansOnly} />

      </Container>
    </article>
  );
};

export default React.memo(Customer);
