import { Container, ContentrArea } from '@ttstr/components';
import React from 'react';

const FansOnly: React.FC = () => {

  return (
    <Container className="">
      <ContentrArea id="fansonly" />
    </Container>
  )
}

export default FansOnly;
