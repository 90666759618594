import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';

import { receiveProducts } from '@ttstr/actions';
import { Container, LoadingSpinner, Masonry, useIntl, Video } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

const ProductListing: React.FC = () => {
  const { t } = useTranslation();
  const { loading, products } = useShallowEqualSelector(mapStateToProps);
  const { receiveProducts } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    receiveProducts();
  }, [language]);

  const videoRaffle = [
    {
      src: require('./assets/videos/bruderII-compressed.mp4'),
      poster: require('./assets/videos/bruderII.mp4.jpg'),
    },
    {
      src: require('./assets/videos/dänemark-compressed.mp4'),
      poster: require('./assets/videos/dänemark.mp4.jpg'),
    },
    {
      src: require('./assets/videos/dynamisch-compressed.mp4'),
      poster: require('./assets/videos/dynamisch.mp4.jpg'),
    },
    {
      src: require('./assets/videos/lächeln-compressed.mp4'),
      poster: require('./assets/videos/lächeln.mp4.jpg'),
    },
    {
      src: require('./assets/videos/pflanzen-compressed.mp4'),
      poster: require('./assets/videos/pflanzen.mp4.jpg'),
    },
    /*
    {
      src: require('./assets/videos/reichtum-compressed.mp4'),
      poster: require('./assets/videos/reichtum.mp4.jpg'),
    },
    */
  ];

  const randomlyPickedVideo = React.useMemo(() => {
    return videoRaffle[Math.floor(videoRaffle.length * Math.random())];
  }, []);

  return (
    <article className="py-5 product-listing">
      <Container className="list-page">
        <Helmet>
          <title>{t(`PRODUCTS.TITLE`)}</title>
        </Helmet>

        <Video
          muted
          preload="auto"
          autoPlay
          loop={false}
          playsInline
          nativeControls={false}
          controls
          controlColor="light"
          controlOutline
          controlsList="nodownload nofullscreen noremoteplayback"
          poster={randomlyPickedVideo.poster}
          containerClassName="mb-4"
        >
          <source src={randomlyPickedVideo.src} type="video/mp4" />
        </Video>

        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />
        ) : products.length === 0 ? ( // if no products
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show products
          <Masonry
            id="product-listing"
            rowClassName="card-columns"
            columnClassName="custom"
            products={products}
            filters="off"
            showSubtitle
          />
        )}
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { products, loading } = state.Products;
  return {
    products,
    loading,
  };
};

const mapDispatchToProps = {
  receiveProducts,
};

export default React.memo(ProductListing);
